
// Vendor
import { defineComponent, onMounted, ref, Ref, watch } from 'vue'

// Components
import CompanyCard from '../children/components/CompanyCard.vue'
import { CompaniesAPI } from '@/api/companies.api'
import { ICompany } from '@/models/jobBank/interfaces/company'
import { JobBankRouteNames } from '@/router/route-names'

export default defineComponent({
  components: {
    CompanyCard
  },
  setup () {
    const hasRequestedCompanies = ref(false)
    const search = ref('')
    const companiesTable = ref()
    const companies: Ref<ICompany[]> = ref([])
    const allCompanies: Ref<ICompany[]> = ref([])
    const getAllCompanies = async () => {
      hasRequestedCompanies.value = false
      allCompanies.value = await CompaniesAPI.findAllCompanies()
      companies.value = allCompanies.value
      hasRequestedCompanies.value = true
    }
    const getCompanies = async () => {
      companies.value = (await CompaniesAPI.findAll(search.value)).data
    }
    const createExcel = () => {
      companiesTable.value.exportCSV()
    }

    onMounted(() => {
      getAllCompanies()
    })

    watch(search, () => {
      if (search.value !== '') {
        getCompanies()
      } else {
        getAllCompanies()
      }
    })

    return {
      companies,
      JobBankRouteNames,
      search,
      companiesTable,
      createExcel,
      allCompanies,
      hasRequestedCompanies
    }
  }
})
