
import { defineComponent, PropType } from 'vue'
import Button from 'primevue/button'
import { ICompany } from '@/models/jobBank/interfaces/company'
import { JobBankRouteNames } from '@/router/route-names'

export default defineComponent({
  components: {
    Button
  },
  props: {
    result: {
      required: true,
      type: Object as PropType<ICompany>
    }
  },
  setup () {
    const editRoute = JobBankRouteNames.EDIT_JOBBANK
    return {
      JobBankRouteNames,
      editRoute
    }
  }
})
