import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-605aaee3")
const _hoisted_1 = { class: "cardBorder" }
const _hoisted_2 = { class: "companyCard" }
const _hoisted_3 = { class: "leftCard" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "centerCard" }
const _hoisted_6 = { class: "rightCard" }
const _hoisted_7 = { class: "card-edit" }
const _hoisted_8 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.result.logo || 'https://logodownload.org/wp-content/uploads/2014/04/toyota-logo-2.png',
          alt: _ctx.result.logo || 'google.png'
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, {
          to: `/job-bank/companies/view/${_ctx.result._id}`
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.result.name), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        _createElementVNode("p", null, "Sector: " + _toDisplayString(_ctx.result.sector), 1)
      ]),
      _createElementVNode("span", _hoisted_6, [
        _createElementVNode("p", null, _toDisplayString(_ctx.result.offers || 100) + " " + _toDisplayString(_ctx.result.offers == 1 ? 'oferta laboral publicada' : 'ofertas laborales publicadas'), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("img", {
        src: require('@/assets/icons/common/edit.svg'),
        alt: "editar"
      }, null, 8, _hoisted_8),
      _createVNode(_component_Button, {
        label: "Editar",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(`/job-bank/companies/view/${_ctx.result._id}/edit/general-info`))),
        class: "p-button-link"
      })
    ])
  ]))
}