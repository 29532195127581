import { Api } from '@/api/api'
import {
  ICompanyResponse,
  CreateCompanyDto,
  ICompany,
  ICompanyDetailResponse
} from '@/models/jobBank/interfaces/company'

class CompaniesApi {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.TALENT_API_ENDPOINT
  }

  public async findAll(search = ''): Promise<ICompanyResponse> {
    return Api.get(`${this.baseURL}/companies?search=${search}`)
  }

  public async getCompanyDetail(
    detail = ''
  ): Promise<ICompanyDetailResponse[]> {
    return Api.get(`${this.baseURL}/companyDetails?type=${detail}`)
  }

  public async findCompany(id: string): Promise<ICompany> {
    return Api.get(`${this.baseURL}/companies/${id}`)
  }

  public async createCompany(company: CreateCompanyDto): Promise<ICompany> {
    return Api.post(`${this.baseURL}/companies`, company)
  }

  public async updateCompany(
    company: CreateCompanyDto,
    id: string
  ): Promise<ICompany> {
    return Api.put(`${this.baseURL}/companies/${id}`, { ...company, id })
  }

  public async findAllCompanies(): Promise<ICompany[]> {
    return Api.get(`${this.baseURL}/companies/report`)
  }
}
export const CompaniesAPI = new CompaniesApi()
